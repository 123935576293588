import React, { useState, useEffect } from 'react';
import ProjectItem from './ProjectItem';
import ProjectComponentDetails from './ProjectComponentDetails';
import '../css/Projects.css';

const Projects = () => {
  const [activeFilter, setActiveFilter] = useState('*');
  const [selectedProject, setSelectedProject] = useState(null);
  const [projectsList, setProjectsList] = useState([]); // Initialize projectsList as empty

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_ADDRESS}/api/projects`) // Make a fetch request to your new Express route
      .then(response => response.json())
      .then(data => setProjectsList(data)) // Update the state with the fetched data
      .catch(err => console.error(err));
  }, []); // Empty dependency array so the fetch only runs once on mount
  
  const handleFilterClick = (filter) => {
    setActiveFilter(filter);
  };

  const handleProjectClick = (projectTitle) => {
    // Find the selected project based on the projectTitle
    const project = projectsList.find((project) => project.projectTitle === projectTitle);
    setSelectedProject(project);
  };

  const closeProjectDetails = () => {
    setSelectedProject(null); // Reset the selected project when the details are closed
  };

  const filteredProjects =
    activeFilter === '*' ? projectsList : projectsList.filter((project) => project.projectCategory[0] === activeFilter);

  return (
    <section className="projects" id="projects">
      <div className="projectContainer">
        <div className="projectRow">
          <div className="projectHeading">
            <h2>Featured <strong>Portfolio</strong></h2>
          </div>
        </div>
        <div className="projectFilters">
          <ul>
            <li className={activeFilter === '*' ? 'activeFilter' : ''} onClick={() => handleFilterClick('*')}>
              All
            </li>
            <li className={activeFilter === 'Artificial Intelligence' ? 'activeFilter' : ''} onClick={() => handleFilterClick('Artificial Intelligence')}>
            Artificial Intelligence
            </li>
            <li className={activeFilter === 'Software Engineering' ? 'activeFilter' : ''} onClick={() => handleFilterClick('Software Engineering')}>
            Software Engineering
            </li>
            {/* Add more categories as needed */}
          </ul>
        </div>
        <div className="projectContent">
          <div className="projectGrid">
            {filteredProjects.map((project, index) => (
              <ProjectItem
                key={index}
                projectTitle={project.projectTitle}
                projectShortSummary={project.projectShortSummary}
                projectCategory={project.projectCategory}
                thumbnail={project.thumbnail}
                technologies={project.Technologies}
                onClick={() => handleProjectClick(project.projectTitle)} // Pass the click handler to ProjectItem
              />
            ))}
          </div>
        </div>
      </div>
      {selectedProject && ( // Render the ProjectComponentDetails if a project is selected
        <ProjectComponentDetails
          projectTitle={selectedProject.projectTitle}
          projectSummary={selectedProject.projectSummary}
          projectCategory={selectedProject.projectCategory}
          technologies={selectedProject.Technologies}
          skillsAcquired={selectedProject.skillsAcquired}
          thumbnail={selectedProject.thumbnail}
          onClose={closeProjectDetails}
        />
      )}
      <br></br>
    </section>
  );
};

export default Projects;