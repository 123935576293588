import { useEffect, useState } from 'react';
import '../css/Projects.css';

const ProjectComponentDetails = ({ projectTitle, projectSummary, projectCategory,
  technologies, skillsAcquired, thumbnail, onClose }) => {

  const [isActive, setIsActive] = useState(false);
  useEffect(() => {
    setIsActive(true);

    const closeOnEscapeKeyDown = (e) => {
      if ((e.charCode || e.keyCode) === 27) {
        onClose();
      }
    }

    document.body.addEventListener('keydown', closeOnEscapeKeyDown);

    return function cleanup() {
      document.body.removeEventListener('keydown', closeOnEscapeKeyDown);
    }
  }, [onClose])


  return (
    <div className="projectComponentDetailsContainer" onClick={onClose}>
      <div
        className={`projectComponentDetailsContent ${isActive ? 'active' : ''}`}
        onClick={e => e.stopPropagation()}
      >
        <div className='projectComponentDetailsInfo'>


          <div className='returnDiv'>
            <button className="closeText" onClick={onClose}>
              &lt; Return to Projects
            </button>
            <button className="closeButton" onClick={onClose}>
              &times;
            </button>

          </div>
          <div className='projectContentTitle'>
            <h2>{projectTitle}</h2>
          </div>
          <div>
            
            <img className="projectContentImage" src={`${process.env.REACT_APP_API_ADDRESS}/pics/${thumbnail}`} alt="" />
          </div>
          <div className='projectSummary'>
            <h3>Summary</h3>
            <p>{projectSummary}</p>
          </div>

          <div className='projectTechnologies'>
            <h3>Technologies</h3>
            <ul className="technologiesList"> 
              {technologies.map((tech, index) => (
                <li key={index}>{tech}</li>
              ))}
            </ul>
          </div>

          <div className='projectSkills'>
            <h3>Skills Acquired</h3>
            <ul className="skillsList">
              {skillsAcquired.map((skill, index) => (
                <li key={index}>{skill}</li>
              ))}
            </ul>
          </div>
          <div className='projectCategory'>
            <h4>Category</h4>
            <div className='category'>
              {projectCategory.map((cat, index) => (
                <div className='categoryItem'>{cat}</div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectComponentDetails;
