import React, { useEffect, useState } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';


import '../css/About.css'
import { IconCake, IconCar, IconPhoneCall, IconHome, IconBrandAsana, IconNotebook, IconMail, IconCalendar } from '@tabler/icons-react';
import { IconCode, IconLanguage, IconBallBasketball, IconMusic, IconPlane, IconDeviceGamepad, IconWalk } from '@tabler/icons-react'; // import necessary icons

// Calculate age based on the birthdate
const birthDate = new Date(2000, 9, 18); // JavaScript months are zero-based, so 9 represents October
const ageInMillis = Date.now() - birthDate.getTime();
const ageInYears = new Date(ageInMillis).getUTCFullYear() - 1970;


// Reusable ListItem component
const ListItem = ({ IconComponent, text }) => (
  <div className='IconLi'>
    <IconComponent className="Icon" />
    <span>{text}</span>
  </div>
);

const Details = ({ className, title, year, institution, place, explanation, company }) => (
  <div className={className}>
    <ul className='educationUL'>
      <li className='TitleDegree'> <h4>{title}</h4></li>
      <li className='liYear'> <IconCalendar className="IconCalendar" /> {year}</li>
      {institution && <li className='liInstitution'>{institution}</li>}
      {explanation && <li className='liExplanation'>{explanation}</li>}
      {company && <li className='liCompany'>{company}</li>}
      <li className='liPlace'>{place}</li>
    </ul>
  </div>
)


const About = () => {
  const [data, setData] = useState({ skills: [], languages: [], interests: [] });
  const [imageList, setImageList] = useState([]);
 
  useEffect(() => {
      fetch(`${process.env.REACT_APP_API_ADDRESS}/api/images`)
        .then(response => response.json())
        .then(urls => setImageList(urls))
        .catch(error => console.log(error));
  }, []);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_ADDRESS}/api/aboutdata`)
      .then(response => response.json())
      .then(data => setData(data))
      .catch(error => console.log(error));
  }, []);


  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((currentIndex + 1) % imageList.length);
    }, 3000);

    return () => clearInterval(timer);
  }, [currentIndex, imageList.length]);


  return (

    <section id="about" className="about">
      <div className="container">

        <div className="section-title">
          <h2>About</h2>
          <p className='aboutTitleText'>I'm a tech enthusiast with a fresh master's degree in Computer Science, specializing in Artificial Intelligence.
                                        Since I was a kid I have always been around computers. Video games introduced me to the world of technology.
                                        Growing up I became passionate about solving real-world problems using algorithms and machine learning. 
                                        Eager to make a significant impact on society and contribute to cutting-edge innovations.
                                        My goal isn't just to get a job; it's to join an organisation that promotes change and has a positive impact on the world. 
                                        I believe that every role, no matter how minor, contributes to the achievement of a larger goal. I'm not looking for a meaningless desk job; I'm looking for a mission.
                                        I invite you to explore my portfolio for projects showcasing my dedication and creativity and I can't wait to build a brighter future together!</p>
        </div>

        <div className="row">
          <div className="pictureAbout">
            <SwitchTransition mode="out-in">
              <CSSTransition
                key={currentIndex}
                timeout={500}
                classNames="slide"
              >
                
                <img src={`${process.env.REACT_APP_API_ADDRESS}`+`/${imageList[currentIndex]}`} alt="Alexandros Christou" />
              </CSSTransition>
            </SwitchTransition>
          </div>
          <div className="col2" >
            <h3 className='title'>AI Wizard: Embarking on an Epic Career Adventure!</h3>
            <p className="titleSubtext">
              Next to my passion for technology, I have a passion for sports. More precisely basketball. From a young age, basketball was more than a sport, it was my life mentor.
              It taught me dedication, which I honed through countless practices, and taught me the essence of perseverance and a winner's mentality. 
              The court emphasised teamwork, teaching me that true success comes from a collaborative effort, a principle I carry into my professional life.
              Further, I had an academic journey taking me to Sweden, where I learned adaptability, resilience, and the richness of diverse cultures amidst new challenges.
              Today, as I enter the professional world of technology, the lessons I learned on the basketball court and during my time in Sweden continue to shape my approach: 
              passionate, dedicated, adaptable, and always team-focused.
            </p>
            <div className="row">
              <div className="col2-1">
                <ul className="first">
                  <li className='IconLi'> <IconCake className="Icon" />  <span>18 October 2000</span></li>
                  <li className='IconLi'> <IconPhoneCall className="Icon" />  <span>+32 477 ** ** **</span></li>
                  <li className='IconLi'> <IconHome className="Icon" />  <span>Brussels, Belgium</span></li>
                </ul>
              </div>
              <div className="col2-1">
                <ul className="first">
                  <li className='IconLi'> <IconBrandAsana className="Icon" />  <span>{ageInYears} years old</span></li>
                  <li className='IconLi'> <IconNotebook className="Icon" />  <span>Master Computer Science</span></li>
                  <li className='IconLi'> <IconMail className="Icon" />  <span>alexchrist@live.be</span></li>
                </ul>
              </div>
              <p>
              
              While I might not have an extensive professional track record, I am thirsty for knowledge and I have a capacity to evolve rapidly. 
              Every challenge I face I view as an opportunity to learn, grow, and refine my abilities rather than a setback.
              I am prepared to relocate if a suitable position presents itself. 
              In essence, I am here to make my efforts count, to contribute to something bigger than myself, and to leave a lasting impression on the world.
              </p>
            </div>
            {/* <p>
              This is a selection of skills.
            </p> */}
          </div>
        </div>
       
        <div className="additional-info">
          <div className="grid-container">
            <div className="info-block">
              <h3>Skills</h3>
              <div className='Skills'>
                {data.skills.map((skill) => (
                  <ListItem IconComponent={IconCode} text={skill} />
                ))}
              </div>
            </div>

            <div className="info-block">
              <h3>Languages</h3>
              <div className='Languages'>
                {data.languages.map((language) => (
                  <ListItem IconComponent={IconLanguage} text={language} />
                ))}
              </div>
            </div>

            <div className="info-block">
              <h3>Interests</h3>
              <div className='Interests'>
                {data.interests.map((interest, index) => {
                  const IconComponent = index === 0 ? IconBallBasketball :
                    index === 1 ? IconDeviceGamepad :
                      index === 2 ? IconCar :
                        index === 3 ? IconPlane : IconMusic;
                  return <ListItem IconComponent={IconComponent} text={interest} />
                })}
              </div>
            </div>
          </div>
        </div>
        <section className="education-experience">
          <article className="columEducation">
            <h3>Education</h3>
            <div className="rowExperience">
              {['Master of Science and Engineering in Computer Science: Artificial Intelligence', 'Bachelor of Science in Computer Science', 'Economy and Modern Languages'].map((title, i) => (
                <Details
                  className='educationDiv'
                  title={title}
                  year={['September 2021 - July 2023', 'September 2018 - July 2021', 'September 2012 - July 2018'][i]}
                  institution={['Vrije Universiteit Brussel', 'Vrije Universiteit Brussel', 'Sint-Martinus Scholen ASO'][i]}
                  place={['Brussels, Belgium', 'Brussels, Belgium', 'Asse, Belgium'][i]}
                />
              ))}
            </div>
          </article>
          <article className="columnExperience">
            <h3>Erasmus</h3>
            <div className="rowExperience">
              <Details
                className='educationDiv'
                title='Master Computer Science/AI Erasmus+ exchange'
                year='August 2022 - Janaury 2023'
                institution='Chalmers University of Technology'
                place='Göteborg, Sweden'
              />
              <h3>Experience</h3>
              <div className="rowExperience">
              <Details
                  className='educationDiv'
                  title='Software Engineering Intern'
                  year='March 2024 - July 2024'
                  explanation='Collaborating with a diverse group of engineers to optimize and validate motor designs for various applications, with a main focus on the Optimus robot. My work involves developing software tools in MATLAB to facilitate detailed comparison of measured and simulated data, significantly scaling existing solutions, and innovating new tools to select optimal powertrain systems with scientific certainty.'
                  company='Tesla Inc.'
                  place='Athens, Greece'
                />
                <Details
                  className='educationDiv'
                  title='Software Developer Intern'
                  year='January 2020 - July 2020'
                  explanation='Improved efficiency by 25% of the parts distribution process to technicians for customer repairs by developing software for
                  hand scanners. Tech stack: C#, .NET, HTML, and SQL databases'
                  company='Vaillant Group'
                  place='Brussels, Belgium'
                />
              </div>
            </div>
          </article>
        </section>

      </div>
    </section >
  );
};

export default About;
