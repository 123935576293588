import React, { useRef, useState } from 'react';
import { faGithub, faInstagram, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSpring, animated } from 'react-spring';
import ReCAPTCHA from "react-google-recaptcha";

import '../css/Contact.css'

const ContactForm = () => {

  const recaptcha = useRef();

  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    message: ''
  });

  const [formErrors, setFormErrors] = useState({
    firstname: null,
    lastname: null,
    email: null,
    message: null,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false); // To track if the form was submitted successfully
  const [submitAnimation, setSubmitAnimation] = useState(''); // Keep track of the button animation state

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const captchaValue = recaptcha.current.getValue();


    let errors = {
      firstname: null,
      lastname: null,
      email: null,
      message: null
    };

    if (!validateEmail(formData.email)) {
      errors.email = 'Invalid email format';
    }

    for (const key in formData) {
      if (formData[key] === '') {
        errors[key] = 'This field is required';
      }
    }

    setFormErrors(errors);
    if (!captchaValue) {
      alert("Please verify the reCAPTCHA!");
    } else {

      fetch(`${process.env.REACT_APP_API_ADDRESS}/verify`, {
        method: "POST",
        body: JSON.stringify({ captchaValue }),
        headers: {
          "content-type": "application/json",
        },
      })
        .then(res => res.json())
        .then(data => {
          // make form submission
          if (data.success)
            
          if (Object.values(errors).every(x => (x === null || x === ''))) {
            console.log('yes')
            setSubmitAnimation('animate'); // Start the button animation
            setIsSubmitting(true);
            fetch(`${process.env.REACT_APP_API_ADDRESS}/api/form`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(formData),
            })
              .then((response) => response.json())
              .then((data) => {
                setIsSubmitting(false);

                if (data.success) {
                  console.log('Email sent successfully');
                  setFormData({
                    firstname: '',
                    lastname: '',
                    email: '',
                    message: '',
                  });
                  setIsSuccess(true);
                  setSubmitAnimation('animate success'); // Show success animation
                  recaptcha.current.reset();

                } else {
                  console.error('An error occurred while sending the email.');
                  setSubmitAnimation('animate error'); // Show error animation
                }
              })
              .catch((error) => {
                setIsSubmitting(false);
                console.error('Error:', error);
                setSubmitAnimation('animate error'); // Show error animation
              });
          } else {
            setSubmitAnimation('animate error'); // Show error animation if form validation fails
          }
        })
        .catch(error => {
          console.error('Verification Error:', error);
        });
    }
  };

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  };

  const props = useSpring({
    opacity: 1,
    from: { opacity: 0 },
  })

  return (
    <section id="contact" className="contact">
      <animated.div style={props} className="contactDiv">
        <div className="contactHeaderDiv">
          <div className='headerDiv'>
            <h2>Need more information?</h2>
            <h4>Let's connect!</h4>
            <p>Thank you for visiting and taking your time to go through my portfolio! Hit me up for any questions or job offers! </p>
          </div>

        </div>
        <div className="contactDetailsDiv">
          <div className="detailsDiv">
            <h3>Find me around</h3>
            <a className='placeText' href="https://www.google.com/maps/place/Brussel" >Brussels, Belgium</a>
            <p className='placeExtraText'>Open for relocation!</p>
          </div>
          <div className="detailsDiv">
            <h3>Socials</h3>
            <div className="social-icons">
              <a href="https://github.com/alexandroschristou" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon className="SocialIcon" icon={faGithub} /></a>
              <a href="https://www.linkedin.com/in/alexandros-christou1" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon className="SocialIcon" icon={faLinkedin} /></a>
              <a href="https://www.instagram.com/alexchristou_" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon className="SocialIcon" icon={faInstagram} /></a>
            </div>
          </div>
          <div className="detailsDiv">
            <h3>Email</h3>
            <p><a className="emailTo" href="mailto:alexchrist@live.be">alexchrist@live.be</a></p>
          </div>
          <div className="detailsDiv">
            <h3>Phone</h3>
            <p>Contact me for my number!</p>
          </div>
        </div>
        <div className="contactFormDiv">
          <form className="form" onSubmit={handleSubmit}>
            <input
              autoComplete="chrome-off"
              type="text"
              name="firstname"
              value={formData.firstname}
              onChange={handleChange}
              placeholder="First Name" />
            {formErrors.firstname && <p className="error">{formErrors.firstname}</p>}
            <input
              autoComplete="chrome-off"
              type="text"
              name="lastname"
              value={formData.lastname}
              onChange={handleChange}
              placeholder="Last Name" />
            {formErrors.lastname && <p className="error">{formErrors.lastname}</p>}
            <input
              autoComplete="chrome-off"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Email" />
            {formErrors.email && <p className="error">{formErrors.email}</p>}
            <textarea
              autoComplete="chrome-off"
              name="message"
              value={formData.message}
              onChange={handleChange}
              placeholder="Your Message">
            </textarea>
            {formErrors.message && <p className="error">{formErrors.message}</p>}
            <input
              className={`inputBtn ${submitAnimation}`}
              type="submit"
              value={submitAnimation === 'animate' ? '' : 'Send'}
              style={submitAnimation === 'animate' ? { padding: '10px 20px' } : {}}
            />
            {submitAnimation === 'animate' && <div className="loader"></div>}


          </form>
          <div className="text-center">
            <ReCAPTCHA className='captcha' ref={recaptcha} sitekey={process.env.REACT_APP_SITE_KEY} />
          </div>

          {isSuccess && <p>Email sent successfully!</p>}
        </div>
      </animated.div>
      <br></br>
    </section>
  );
};

export default ContactForm;
