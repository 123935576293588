
import '../css/Home.css';
import Blinking from './BlinkingText';

const Home = () => {

  const wordsList = ['Ambitious', 'Problem-solver', 'Visionary', 'Leadership']
  const HellowordsList = ['Hello!', 'Bonjour!', 'Hallo!', 'Kαλημέρα!']

  return (
    <section id="home" className="home">
    <div className="homepage">
      <div className='picture'>
        <img src={`${process.env.REACT_APP_API_ADDRESS}/images/alex.jpg`} alt="Alexandros Christou" width="200" height="200" />
        <div className="PictureText">
          <h1 ><Blinking InputList={HellowordsList} pauseTime={1000} /> I am Alexandros Christou</h1>
          <h3 className='subtitle'>
          Ambitious | Problem-solver | Team-Player | Leadership
            {/* <Blinking InputList={wordsList} pauseTime={2000} /> */}
          </h3>
        </div>
      </div>

    </div>
    </section>
  );
};

export default Home;