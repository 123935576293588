import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import '../css/Navbar.css';

const Navbar = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showDropdown, setShowDropdown] = useState(false);
  const [activeLink, setActiveLink] = useState('');
  const [displayedLink, setDisplayedLink] = useState(''); // New state to store the displayed link name

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth > 800) {
      // Hide the dropdown when window size is larger than 768px
      setShowDropdown(false);
    }
  }, [windowWidth]);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleLinkClick = (link) => {
    setActiveLink(link);
    setDisplayedLink(link);
    if (windowWidth <= 800) {
      // Close the dropdown after clicking a link on mobile view
      setShowDropdown(false);
    }
  };

  const renderNavLinks = () => {
    return (
      <ul className="navbar-list">
        <li className="navbar-item">
          <NavLink
            to="/"
            className={`navbar-link ${activeLink === 'home' ? 'active' : ''}`}
            onClick={() => handleLinkClick('home')}
          >
            Home
          </NavLink>
        </li>
        <li className="navbar-item">
          <NavLink
            to="/about"
            className={`navbar-link ${activeLink === 'about' ? 'active' : ''}`}
            onClick={() => handleLinkClick('about')}
          >
            Who am I?
          </NavLink>
        </li>
        <li className="navbar-item">
          <NavLink
            to="/projects"
            className={`navbar-link ${activeLink === 'projects' ? 'active' : ''}`}
            onClick={() => handleLinkClick('projects')}
          >
            Projects
          </NavLink>
        </li>
        <li className="navbar-item">
          <NavLink
            to="/contact"
            className={`navbar-link ${activeLink === 'contact' ? 'active' : ''}`}
            onClick={() => handleLinkClick('contact')}
          >
            Contact
          </NavLink>
        </li>
        {/* Add more navigation links here */}
      </ul>
    );
  };

  const renderDropdown = () => {
    return <div className="navbar-dropdown">{renderNavLinks()}</div>;
  };

  return (
    <nav className="navbar">
      {windowWidth <= 800 ? (
        <div>
          <div className="navbar-toggle-container">
            <input type="checkbox" id="checkbox" className="navbar-toggle" checked={showDropdown} onChange={toggleDropdown}></input>
            <label htmlFor="checkbox" className="toggle">
              <div className="bars" id="bar1"></div>
              <div className="bars" id="bar2"></div>
              <div className="bars" id="bar3"></div>
            </label>
            {!showDropdown && <span className="displayed-link">{displayedLink.toUpperCase()}</span>}
          </div>
          {showDropdown && renderDropdown()}
        </div>
      ) : (
        renderNavLinks()
      )}
    </nav>
  );
};

export default Navbar;