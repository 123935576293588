import React, { useState, useEffect} from 'react';
import '../css/Blinking.css';

const Blinking = (props) => {

  const wordsList = props.InputList 
//   ['Professional', 'Problem solver', 'Hello', 'HAHAHA'];
  const [currentWord, setCurrentWord] = useState(0);
  const [isErasing, setIsErasing] = useState(false);
  const [charIndex, setCharIndex] = useState(0);
  const typingSpeed = 100; // Adjust typing speed in milliseconds
  const eraseSpeed = 50; // Adjust erasing speed in milliseconds
  const pauseTime = props.pauseTime; // Time to pause after a word has been typed before erasing

  useEffect(() => {
    let timeout;
    const currentWordText = wordsList[currentWord];

    if (currentWordText) {
      if (!isErasing) {
        // Typing
        if (charIndex < currentWordText.length) {
          timeout = setTimeout(() => {
            setCharIndex((prevIndex) => prevIndex + 1);
          }, typingSpeed);
        } else {
          // Word fully typed, wait before erasing
          timeout = setTimeout(() => {
            setIsErasing(true);
          }, pauseTime);
        }
      } else {
        // Erasing
        if (charIndex > 1) {
          timeout = setTimeout(() => {
            setCharIndex((prevIndex) => prevIndex - 1);
          }, eraseSpeed);
        } else {
          // Word fully erased, go to the next word
          setIsErasing(false);
          setCurrentWord((prevWord) => (prevWord + 1) % wordsList.length);
        }
      }
    }

    return () => clearTimeout(timeout);
  }, [charIndex, currentWord, isErasing, pauseTime, wordsList]);

  useEffect(() => {
    setCurrentWord(0); // Start with the first word initially
  }, []);
  
  const cursor = isErasing ? '|' : '|';

  return (
    <div className="Blinking">
      {' '} <span className="dynamic-text">{wordsList[currentWord] && wordsList[currentWord].substring(0, charIndex)}</span>
      <span className="cursor">{cursor}</span>
    </div>
    );
};

export default Blinking;