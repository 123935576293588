import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import Projects from './components/Projects';
import Contact from './components/Contact.js';
import Navbar from './components/Navbar';
import About from './components/About';
import './App.css';
import ReactGA from 'react-ga';


function App({ history }) {
  // ReactGA.pageview(window.location.pathname + window.location.search);
//   history.listen((location, action) => {
//     ReactGA.set({ page: location.pathname });
//     ReactGA.pageview(location.pathname);
// });
  return (
    <Router>
      
      <div className="App">
      <Navbar />
    
        <main className='main'>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;