import React from 'react';
import '../css/Projects.css';

const ProjectItem = (props) => {
    const { projectTitle, projectShortSummary, projectCategory, thumbnail, technologies, onClick } = props;

    return (
        <div className="projectItem" onClick={onClick}>
            <div className="projectThumb">
                <div className="projectOverlay"></div>
                <img className="projectImage" src={`${process.env.REACT_APP_API_ADDRESS}/pics/${thumbnail}`} alt="" />
            </div>
            <div className="projectMiddle">
                <div className="projectText">
                    <h2>{projectTitle}</h2>
                    <div className='description'> {projectShortSummary} </div>
                    <div className='projectSkills'>
                        <ul className="skillsList">
                            {technologies.map((skill, index) => (
                                <li key={index}>{skill}</li>
                            ))}
                        </ul>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default ProjectItem;